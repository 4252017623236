import React, { useState } from 'react'
import validator from 'validator'
import styled from '@emotion/styled'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyIcon from '@mui/icons-material/Key'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import PageWrapper from '../components/layout/PageWrapper'
import {
  CustomTextField,
  CustomTextFieldInputProps,
  ShellContainer,
  SubmitButton,
  Title,
} from '../components/auth/LoginSignupShellComponents'
import { ERROR_MESSAGES } from '../constants'
import { auth } from '../firebase-config'

const SubText = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 12px;
`

const BackButton = styled(Button)`
  border-radius: 10px;
  text-transform: none;
`

const IconContainer = styled(Grid)`
  margin-right: 10px;
`

// Strings of text
const title = 'Forgot Password?'
const titleSuccess = 'Success!'
const subText = 'No worries! Enter the email associated with your account to receive instructions to reset your password.'
const subTextSuccess = 'We sent you an email with instructions to reset your password.'

const PasswordReset = () => {
  const navigate = useNavigate()

  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onChange = (e) => {
    setErrors({})
    setEmail(e.target.value)
  }

  const onSubmit = () => {
    setLoading(true)

    if(validateInput()) {
      // Call firebase function to send password reset email
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          setSuccess(true)
        })
        .catch((error) => {
          const errorCode = error.code
          // const errorMessage = error.message
          switch (errorCode) {
            case 'auth/user-not-found':
              setErrors({ email: ERROR_MESSAGES.ACCOUNT_NOT_FOUND })
              break;
            default:
              setErrors({ email: ERROR_MESSAGES.GENERIC_ERROR_FOUND })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const validateInput = () => {
    const tempErrors = {}

    if (validator.isEmpty(email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_REQUIRED_TEXT
    } else if (!validator.isEmail(email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_INVALID_TEXT
    }

    setErrors(tempErrors)

    return (Object.keys(tempErrors).length === 0)
  }

  return (
    <PageWrapper>
      <Grid container justifyContent='center'>
        <ShellContainer>
          <Grid container alignItems='center'>
            <IconContainer item>
              {success ? (
                <CheckCircleIcon fontSize='large' color='success' />
              ) : (
                <KeyIcon fontSize='large' color='text' />
              )}
            </IconContainer>
            <Grid item>
              <Title variant='h5'>
                {success ? titleSuccess : title}
              </Title>
            </Grid>
          </Grid>
          <SubText>
            {success ? subTextSuccess : subText}
          </SubText>
          {!success && (
            <>
              <CustomTextField
                variant='outlined'
                onChange={onChange}
                value={email}
                error={!!errors.email}
                helperText={errors.email}
                id='email'
                type='email'
                label='Email'
                placeholder='Email'
                fullWidth
                InputProps={{
                  style: CustomTextFieldInputProps,
                }}
              />
              <SubmitButton
                type='submit'
                color='primary'
                variant='contained'
                onClick={onSubmit}
                fullWidth
                disableElevation
                size='large'
              >
                {!loading ? (
                  <>
                    Reset password
                  </>
                ) : (
                  <span style={{ height: 24 }}>
                    <CircularProgress size={20} color='secondary' />
                  </span>
                )}
              </SubmitButton>
            </>
          )}
          <BackButton
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/login')}
          >
            Go Back to Login
          </BackButton>
        </ShellContainer>
      </Grid>
    </PageWrapper>
  )
}

export default PasswordReset
