import React, { useState } from 'react'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'

import styled from '@emotion/styled'
import { Button } from '@mui/material'

import LogInSignUpShell from '../components/auth/LogInSignUpShell'
import PageWrapper from '../components/layout/PageWrapper'
import { CustomTextField, CustomTextFieldInputProps } from '../components/auth/LoginSignupShellComponents'
import { auth } from '../firebase-config'
import { ERROR_MESSAGES } from '../constants'

const emptyFieldsState = {
  email: '',
  password: '',
}

const ForgotPassButton = styled(Button)`
  margin-top: 14px;
  float: right;
  text-transform: none;
  border-radius: 10px;
`

const LogIn = () => {
  const navigate = useNavigate()
  const googleAuthProvider = new GoogleAuthProvider()

  const [fields, setFields] = useState(emptyFieldsState)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const bottomTextObj = {
    initialText: 'Don\'t have an account?',
    onClick: () => navigate('/signup'),
    actionText: 'Sign Up',
  }

  const onChange = (e) => {
    setErrors({})
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    })
  }

  const onSubmit = () => {
    setLoading(true)

    if (validateInput()) {
      // Call firebase function to sign in
      signInWithEmailAndPassword(auth, fields.email, fields.password)
        .then((userCredential) => {
          // Signed in Successfully
          // const user = userCredential.user
          navigate('/home')
        })
        .catch((error) => {
          const errorCode = error.code
          // const errorMessage = error.message
          switch (errorCode) {
            case 'auth/too-many-requests':
              setErrors({ password: ERROR_MESSAGES.ACCOUNT_DISABLED_MESSAGE })
              break
            case 'auth/wrong-password':
            default:
              setErrors({ password: ERROR_MESSAGES.EMAIL_OR_PASS_INVALID_TEXT })
          }
        })
        .finally(() => {
          setLoading(false)
        })

    } else {
      setLoading(false)
    }
  }

  const validateInput = () => {
    const tempErrors = {}
    // Email checks
    if (validator.isEmpty(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_REQUIRED_TEXT
    } else if (!validator.isEmail(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_INVALID_TEXT
    }
    // Password checks
    if (validator.isEmpty(fields.password)) {
      tempErrors.password = ERROR_MESSAGES.PASSWORD_REQUIRED_TEXT
    }

    setErrors(tempErrors)

    return (Object.keys(tempErrors).length === 0)
  }

  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      onSubmit()
    }
  }

  const handleGoogleClick = () => {
    // USE SIGN IN WITH REDIRECT FOR MOBILE
    signInWithPopup(auth, googleAuthProvider)
      .then((res) => {
        // Success
        navigate('/home')
      })
      .catch((err) => {
        // Error
        console.log('Google Auth Error on log in')
      })
  }

  const loginFieldsUI = (
    <>
      {/* Email Field */}
      <CustomTextField
        variant='outlined'
        onChange={onChange}
        value={fields.email}
        error={!!errors.email}
        helperText={errors.email}
        id='email'
        type='email'
        label='Email'
        placeholder='Email'
        fullWidth
        InputProps={{
          style: CustomTextFieldInputProps,
        }}
      />
      {/* Password Field */}
      <CustomTextField
        variant='outlined'
        onChange={onChange}
        onKeyDown={handleKeyPress}
        value={fields.password}
        error={!!errors.password}
        helperText={errors.password}
        id='password'
        type='password'
        label='Password'
        placeholder='Password'
        fullWidth
        InputProps={{
          style: CustomTextFieldInputProps,
        }}
      />
      {/* Forgot Password Text */}
      <ForgotPassButton onClick={() => navigate('/password-reset')}>
        Forgot password?
      </ForgotPassButton>
    </>
  )

  return (
    <PageWrapper padding='0px'>
      <LogInSignUpShell
        title='Log In'
        onSubmit={onSubmit}
        bottomTextObj={bottomTextObj}
        loading={loading}
        googleText='Log in with Google'
        onGoogleClick={handleGoogleClick}
      >
        {loginFieldsUI}
      </LogInSignUpShell>
    </PageWrapper>
  )
}

export default LogIn
