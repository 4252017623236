// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
// TODO: Add sign up with google
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBpE4_fP5X9y1LLGfwEBnQJg7fqrfJnNMM',
  authDomain: 'interview-tracker-2dd08.firebaseapp.com',
  projectId: 'interview-tracker-2dd08',
  storageBucket: 'interview-tracker-2dd08.appspot.com',
  messagingSenderId: '724864910073',
  appId: '1:724864910073:web:d4f71eaaa6b568afd45828',
  measurementId: 'G-Q1ZH69N2EG'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

export const db = getFirestore(app)

export const auth = getAuth(app)