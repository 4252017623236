import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { v4 } from 'uuid'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import PageWrapper from '../layout/PageWrapper'
import CompanyAutocomplete from './CompanyAutocomplete'
import { ERROR_MESSAGES, MOBILE_MAX_WIDTH, statusOptions } from '../../constants'
import { db } from '../../firebase-config'
import { addInterview } from '../../redux/interviews'
import { FieldWrapper } from '../form/FormComponents'
import { CustomTextFieldInputProps } from '../auth/LoginSignupShellComponents'

const TopContainer = styled(Grid)`
  margin-bottom: 26px;
`

const Title = styled(Typography)`
  font-weight: bold;
  padding: 10px 0px;
`

const CustomTextField = styled(TextField)`
  margin-top: 20px;
`

const AutocompleteContainer = styled.div`
  margin-top: 20px;
`

const CustomSelect = styled(Select)`
  margin-top: 20px;
  border-radius: 10px;
`

const SubmitButton = styled(Button)`
  border-radius: 10px;
  text-transform: none;
  color: white;
  font-weight: bold;
`

const CancelButton = styled(Button)(({ theme }) => ({
  marginRight: '16px',
  textTransform: 'none',
  borderRadius: 10,
  fontWeight: 'bold',
  color: theme.palette.neutral.dark,
  backgroundColor: theme.palette.neutral.main,
  '&:hover': {
    backgroundColor: theme.palette.neutral.main,
    boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  },
}))

const MenuProps = {
  PaperProps: {
    style: {
      border: '2px solid black',
      borderRadius: 10,
      maxHeight: 300,
      minWidth: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
};

const emptyFieldsState = {
  company: {},
  position: '',
  dateApplied: dayjs().format(),
  status: statusOptions[0],
}

const AddInterview = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH})`)

  const [fields, setFields] = useState(emptyFieldsState)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    }
  })

  const validateInput = () => {
    const tempErrors = {}
    // Company field checks
    if (Object.keys(fields.company).length === 0) {
      tempErrors.company = ERROR_MESSAGES.COMPANY_EMPTY
    }
    // Position field checks
    if (validator.isEmpty(fields.position)) {
      tempErrors.position = ERROR_MESSAGES.POSITION_EMPTY
    }

    setErrors(tempErrors)

    return (Object.keys(tempErrors).length === 0)
  }

  const onSubmit = async () => {
    await setLoading(true)
    if (validateInput()) {
      const userInterviewsRef = doc(db, 'interviews', user.uid)
      const newInterviewId = v4()

      const document = await getDoc(userInterviewsRef)
      
      // If doc doesn't exist, add new
      if (!document.exists()) {
        await setDoc(userInterviewsRef, {
          [newInterviewId]: fields,
        }).then(() => {
          // TODO: Add success signal
          dispatch(addInterview({ newInterviewId, fields }))
          navigate('/home')
        }).catch((err) => {
          console.log('ERROR TRYING TO ADD DOC: ', err)
        }).finally(() => {
          setLoading(false)
        })

      // Else, add to existing doc
      } else {
        await updateDoc(userInterviewsRef, {
          [newInterviewId]: fields,
        }).then(() => {
          // TODO: Add success signal
          dispatch(addInterview({ newInterviewId, fields }))
          navigate('/home')
        }).catch((err) => {
          console.log('ERROR TRYING TO UPDATE DOC: ', err)
        }).finally(() => {
          setLoading(false)
        })
      }
    }

    await setLoading(false)
  }

  const onChangeField = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  return (
    <PageWrapper width='800px' padding={isMobile && '26px'}>
      <TopContainer container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Title variant='h1'>
            Add New
          </Title>
        </Grid>
        {isMobile && (
          <Grid item>
            <IconButton onClick={() => navigate('/home')}>
              <ArrowBackIcon
                sx={{ fontSize: '2rem' }}
                color='secondary'
              />
            </IconButton>
          </Grid>
        )}
      </TopContainer>

      {/* Company - Autocomplete Dropdown */}
      <FieldWrapper label='Company'>
        <AutocompleteContainer>
          <CompanyAutocomplete
            updateSelectedCompany={(value) => {
              onChangeField('company', value)
            }}
            errorText={errors.company}
          />
        </AutocompleteContainer>
      </FieldWrapper>

      {/* Position - Text Field */}
      <FieldWrapper label='Position'>
        <CustomTextField
          variant='outlined'
          onChange={(e) => {
            onChangeField(e.target.id, e.target.value)
          }}
          inputProps={{
            maxLength: 80,
          }}
          value={fields.position}
          error={!!errors.position}
          helperText={errors.position}
          id='position'
          placeholder='Eg. Software Engineer'
          fullWidth
          InputProps={{
            style: CustomTextFieldInputProps,
          }}
        />
      </FieldWrapper>

      {/* Status - Selector */}
      <FieldWrapper label='Status'>
        <CustomSelect
          value={fields.status}
          fullWidth
          onChange={(e) => {
            onChangeField('status', e.target.value)
          }}
          MenuProps={MenuProps}
        >
          {statusOptions.map((option, i) => {
            return (
              <MenuItem value={option} key={i}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircleIcon
                    sx={{
                      color: `${option.color}`,
                      marginRight: '8px',
                    }}
                  />
                  {option.label}
                </div>
              </MenuItem>
            )
          })}
        </CustomSelect>
      </FieldWrapper>

      {/* Date Applied - Date Time Picker  */}
      <FieldWrapper label='Date Applied'>
        <DesktopDatePicker
          inputFormat='MM/DD/YYYY'
          value={fields.dateApplied}
          onChange={(newDate) => {
            onChangeField('dateApplied', newDate.format())
          }}
          id='dateApplied'
          disabled={fields.status.label === 'Wishlist'}
          InputProps={{
            style: {
              borderRadius: 10,
              marginTop: 20,
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                placeholder: 'MM/DD/YYYY'
              }}
            />
          )}
        />
      </FieldWrapper>

      <Grid container justifyContent='flex-end'>
        {!isMobile && (
          <CancelButton
            color='primary'
            variant='contained'
            disableElevation
            size='large'
            onClick={() => {
              navigate('/home')
            }}
          >
            Cancel
          </CancelButton>
        )}
        <SubmitButton
          type='submit'
          color='primary'
          variant='contained'
          disableElevation
          size='large'
          onClick={onSubmit}
          fullWidth={isMobile}
        >
          {loading ? (
            <CircularProgress sx={{ color: 'white' }} size={20} />
          ) : (
            <>
              Save
            </>
          )}
        </SubmitButton>
      </Grid>
    </PageWrapper>
  )
}

export default AddInterview
