import React, { useState } from 'react'
import { deleteField, doc, updateDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'

import {
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material'
import styled from '@emotion/styled'

import theme from '../../theme'
import CustomDialog from '../shared/CustomDialog'
import { db } from '../../firebase-config'
import { deleteInterview } from '../../redux/interviews'

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  }
}))

const ListStyles = {
  padding: 0,
}

const RemoveInterview = ({
  anchor,
  handleClose,
  interviewDetails,
  interviewKey,
  onInterviewRemoved,
}) => {
  const dispatch = useDispatch()
  const [showDialog, setShowDialog] = useState(false)
  const [removing, setRemoving] = useState(false)
  const open = Boolean(anchor)

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    }
  })

  const handleShowDialog = () => {
    handleClose()
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const handleRemoveInterview = async () => {
    await setRemoving(true)
    const interviewRef = doc(db, 'interviews', user.uid)
    await updateDoc(interviewRef, {
      [interviewKey]: deleteField(),
    }).then(() => {
      dispatch(deleteInterview(interviewKey))
      onInterviewRemoved()
    }).catch((e) => {
      // TODO: Add error alert
      console.log('Error removing interview!')
    }).finally(() => {
      setRemoving(false)
    })
  }

  return (
    <>
      <StyledPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={ListStyles}>
          <ListItemButton onClick={handleShowDialog}>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  color: theme.palette.error.main,
                }
              }}
            >
              Remove
            </ListItemText>
          </ListItemButton>
        </List>
      </StyledPopover>
      {showDialog && (
        <CustomDialog
          open={showDialog}
          handleClose={handleCloseDialog}
          actionClick={handleRemoveInterview}
          title='Are you sure you want to remove this interview?'
          contentText={`This will permanently delete your interview with ${interviewDetails.company.label}`}
          actionButtonText={removing ? (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{
                height: 24,
                width: 50,
              }}
            >
              <CircularProgress size={20} color='secondary' />
            </Grid>
           ) : 'Remove'}
        />
      )}
    </>
  )
}

export default RemoveInterview
