import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RequireNoAuth = ({ children }) => {
  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    }
  })

  if (auth.isAuthenticated) {
    return <Navigate to="/home" />
  }

  return children
}

export default RequireNoAuth
