import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'

import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import styled from '@emotion/styled'
import theme from '../../theme'

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  }
}))

const ListStyles = {
  width: '240px',
}

const UserProfile = () => {
  const navigate = useNavigate()
  const firebaseAuth = getAuth()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleUserIconClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAccountClick = () => {
    navigate('/account')
    handleClose()
  }

  const handleContactClick = () => {
    navigate('/contact')
    handleClose()
  }

  const handleLogout = () => {
    signOut(firebaseAuth).then(() => {
      // Sign out successful
      navigate('/login')
    }).catch((error) => {
      // Sign out error
      console.log('Error signing out')
    })
  }

  const openPopover = Boolean(anchorEl)

  const CustomListItem = ({ label, onClick }) => {
    return (
      <ListItemButton onClick={onClick}>
        <ListItemText>
          {label}
        </ListItemText>
      </ListItemButton>
    )
  }

  return (
    <>
      <IconButton onClick={handleUserIconClick}>
        <AccountCircleIcon color={theme.palette.neutral.dark} />
      </IconButton>
      <StyledPopover
        open={openPopover}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={ListStyles}>
          <CustomListItem label='Account' onClick={handleAccountClick} />
          <CustomListItem label='Contact Us' onClick={handleContactClick} />
          <Divider />
          <CustomListItem label='Log out' onClick={handleLogout} />
        </List>
      </StyledPopover>
      {/* <Snackbar open={true}>
        <Alert
          severity='info'
          color='primary'
          // onClose={handleCloseError}
          sx={{
            borderRadius: 10,
          }}
          variant='filled'
        >
          Successfully logged out.
        </Alert>
      </Snackbar> */}
    </>
  )
}

export default UserProfile
