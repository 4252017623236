import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  interviews: {},
  loading: true,
}

const interviewsSlice = createSlice({
  name: 'interviews',
  initialState: initialState,
  reducers: {
    updateInterviews(state, action) {
      const interviews = action.payload
      state.interviews = interviews
      return state
    },
    addInterview(state, action) {
      const { newInterviewId, fields } = action.payload
      state.interviews = {
        ...state.interviews,
        [newInterviewId]: fields,
      }
      return state
    },
    deleteInterview(state, action) {
      const interviewKey = action.payload
      const currentInterviews = state.interviews
      const newInterviews = Object.entries(currentInterviews).filter(
        ([k, v]) => k !== interviewKey
      )
      state.interviews = Object.fromEntries(newInterviews)
      return state
    },
    setLoading(state, action) {
      const newStatus = action.payload
      state.loading = newStatus
      return state
    }
  }
})

export const {
  updateInterviews,
  setLoading,
  deleteInterview,
  addInterview
} = interviewsSlice.actions

export default interviewsSlice.reducer