import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import interviewsReducer from './interviews'

const rootReducer = combineReducers({
  auth: authReducer,
  interviews: interviewsReducer
})

const store = configureStore({ reducer: rootReducer })

export default store
