import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import emailjs from '@emailjs/browser'

import { TextField, Typography, Button, Grid, InputAdornment, CircularProgress } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import EmailIcon from '@mui/icons-material/Email'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import PageWrapper from '../components/layout/PageWrapper'
import { CustomTextFieldInputProps, FieldWrapper } from '../components/form/FormComponents'
import theme from '../theme'
import { ERROR_MESSAGES } from '../constants'

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 20px;
`

const SuccessTitle = styled(Typography)`
  font-weight: bold;
`

const SuccessTitleContainer = styled(Grid)`
  margin-bottom: 20px;
`

const SubTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 32px;
`

const CustomTextField = styled(TextField)`
  margin-top: 20px;
`

const SubmitButton = styled(Button)`
  border-radius: 10px;
  text-transform: none;
  color: white;
  font-weight: bold;
`

const CancelButton = styled(Button)(({ theme }) => ({
  marginRight: '16px',
  textTransform: 'none',
  borderRadius: 10,
  fontWeight: 'bold',
  color: theme.palette.neutral.dark,
  backgroundColor: theme.palette.neutral.main,
  '&:hover': {
    backgroundColor: theme.palette.neutral.main,
    boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  },
}))

const emptyFieldsState = {
  name: '',
  email: '',
  topic: '',
  message: '',
}

const commentMaxLength = 300

const SERVICE_ID = 'service_twr0158'
const TEMPLATE_ID = 'template_kr62ksd'
const PUBLIC_KEY = 'yJSq_D09evc3MZRCf'

const ContactUs = () => {
  const navigate = useNavigate()
  const [fields, setFields] = useState(emptyFieldsState)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    }
  })

  useEffect(() => {
    if (user && user.displayName && user.email) {
      setFields({
        ...fields,
        name: user.displayName,
        email: user.email,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onChangeField = (field, value) => {
    setFields({
      ...fields,
      [field]: value,
    })
  }

  const validateInput = () => {
    const tempErrors = {}

    // Name checks
    if (validator.isEmpty(fields.name)) {
      tempErrors.name = ERROR_MESSAGES.NAME_REQUIRED_TEXT
    }
    // Email checks
    if (validator.isEmpty(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_REQUIRED_TEXT
    } else if (!validator.isEmail(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_INVALID_TEXT
    }
    // Topic checks
    if (validator.isEmpty(fields.topic)) {
      tempErrors.topic = ERROR_MESSAGES.TOPIC_REQUIRED_TEXT
    }
    // Message checks
    if (validator.isEmpty(fields.message)) {
      tempErrors.message = ERROR_MESSAGES.MESSAGE_REQUIRED_TEXT
    }

    setErrors(tempErrors)

    return (Object.keys(tempErrors).length === 0)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    return emailjs.send(SERVICE_ID, TEMPLATE_ID, fields, PUBLIC_KEY)
      .then((result) => {
          setSuccess(true)
      }, (error) => {
          // TODO: Add error alert
          console.log('Error sending email')
      });
  }

  const onSubmit = async (e) => {
    await setLoading(true)
    if (validateInput()) {
      await sendEmail(e)
    }
    await setLoading(false)
  }

  if (success) {
    return (
      <PageWrapper width='800px'>
        <SuccessTitleContainer container alignItems='center'>
          <Grid item>
            <CheckCircleIcon
              sx={{
                fontSize: '4rem',
                marginRight: 2,
              }}
              color='success'
            />
          </Grid>
          <Grid item>
            <SuccessTitle variant='h1'>
              Message Sent!
            </SuccessTitle>
          </Grid>
        </SuccessTitleContainer>
        <SubTitle variant='h5' sx={{ color: theme.palette.text.light }}>
          Your message has been successfully received by our team.
          <div>We’ll get back to you as soon as possible!</div>
        </SubTitle>
        <CancelButton
          color='primary'
          variant='contained'
          disableElevation
          size='large'
          onClick={() => {
            navigate(-1)
          }}
        >
          Return
        </CancelButton>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper width='800px'>
      <Title variant='h1'>
        Contact Us
      </Title>
      <SubTitle variant='h5' sx={{ color: theme.palette.text.light }}>
        Ask our team anything, anytime. We’re happy to help with any questions or feedback you may have!
      </SubTitle>

      {/* Name Field */}
      <FieldWrapper label='Name'>
        <CustomTextField
          variant='outlined'
          onChange={(e) => {
            onChangeField(e.target.id, e.target.value)
          }}
          inputProps={{
            maxLength: 80,
          }}
          value={fields.name}
          error={!!errors.name}
          helperText={errors.name}
          id='name'
          placeholder='Name'
          fullWidth
          InputProps={{
            style: CustomTextFieldInputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <AccountCircleIcon />
              </InputAdornment>
            )
          }}
        />
      </FieldWrapper>

      {/* Email Field */}
      <FieldWrapper label='Email'>
        <CustomTextField
          variant='outlined'
          onChange={(e) => {
            onChangeField(e.target.id, e.target.value)
          }}
          inputProps={{
            maxLength: 80,
          }}
          value={fields.email}
          error={!!errors.email}
          helperText={errors.email}
          disabled={!!user.email}
          id='email'
          placeholder='Email'
          fullWidth
          InputProps={{
            style: CustomTextFieldInputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <EmailIcon />
              </InputAdornment>
            )
          }}
        />
      </FieldWrapper>

      {/* Topic Field */}
      <FieldWrapper label='Topic'>
        <CustomTextField
          variant='outlined'
          onChange={(e) => {
            onChangeField(e.target.id, e.target.value)
          }}
          inputProps={{
            maxLength: 80,
          }}
          value={fields.topic}
          error={!!errors.topic}
          helperText={errors.topic}
          id='topic'
          placeholder='Eg. Bug Report, Login Help, Improvement Suggestion'
          fullWidth
          InputProps={{
            style: CustomTextFieldInputProps,
          }}
        />
      </FieldWrapper>

      {/* Message Field */}
      <FieldWrapper label='Message'>
        <CustomTextField
          variant='outlined'
          onChange={(e) => {
            onChangeField(e.target.id, e.target.value)
          }}
          inputProps={{
            maxLength: commentMaxLength,
          }}
          multiline
          rows={4}
          value={fields.message}
          error={!!errors.message}
          helperText={errors.message}
          id='message'
          placeholder='Type anything...'
          fullWidth
          InputProps={{
            style: CustomTextFieldInputProps,
          }}
        />
      </FieldWrapper>

      {/* Buttons */}
      <Grid container justifyContent='flex-end'>
        <CancelButton
          color='primary'
          variant='contained'
          disableElevation
          size='large'
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </CancelButton>
        <SubmitButton
          type='submit'
          color='primary'
          variant='contained'
          disableElevation
          size='large'
          onClick={onSubmit}
        >
          {loading ? (
            <CircularProgress sx={{ color: 'white' }} size={20} />
          ) : (
            <>
              Send
            </>
          )}
        </SubmitButton>
      </Grid>
    </PageWrapper>
  )
}

export default ContactUs
