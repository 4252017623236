import React from 'react'
import styled from '@emotion/styled'
import theme from '../../theme'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const BackgroundContainer = styled(Grid)`
  background: linear-gradient(
    to top,
    ${theme.palette.primary.light} 0%,
    ${theme.palette.primary.light} 50%,
    white 50%,
    white 100%
  );
  padding: 140px 0px;
`

const Container = styled.div`
  padding: 40px 40px 0px 40px;
  max-width: 920px;

  background-color: white;
  border: 4px solid ${theme.palette.neutral.main};
  border-radius: 20px;
`

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
`

const NumberContainer = styled(Grid)`
  border-radius: 50%;
  height: 55px;
  width: 55px;
  background-color: ${theme.palette.primary.main};
`

const Number = styled(Typography)`
  font-weight: bold;
  color: white;
`

const ColumnItem = styled(Grid)`
  display: flex;
  justify-content: center;
`

const MainText = styled(Typography)`
  font-weight: bold;
  padding: 20px 0px;
  text-align: center;
`

const SubText = styled(Typography)`
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  color: ${theme.palette.text.light}
`

const SignUpButton = styled(Button)`
  text-transform: none;
  margin-top: 20px;
  padding: 12px 32px 12px 32px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 30px;
`

const Column = ({
  num,
  mainText,
  subText,
  imgSrc,
  altTxt,
  navigate,
}) => {
  return (
    <Grid
      item
      xs={4}
      sx={{
        padding: '0px 20px',
      }}
    >
      <Grid container direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
        <Grid item xs>
          <Grid container justifyContent='center'>
            <ColumnItem item xs={12}>
              <NumberContainer
                container
                justifyContent='center'
                alignContent='center'
              >
                <Number variant='h3'>
                  {num}
                </Number>
              </NumberContainer>
            </ColumnItem>
            <ColumnItem item xs={12}>
              <MainText variant='h3'>
                {mainText}
              </MainText>
            </ColumnItem>
            <ColumnItem item xs={12}>
              <SubText>
                {subText}
              </SubText>
            </ColumnItem>
            {(num === 1) && (
              <ColumnItem item xs={12}>
                <SignUpButton
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={() => navigate('/signup')}
                  fullWidth
                >
                  Sign Up
                </SignUpButton>
              </ColumnItem>
            )}
          </Grid>
        </Grid>
        {(num !== 1) && (
          <Grid item>
            <img
              src={imgSrc}
              width='100%'
              alt={altTxt}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const GettingStarted = () => {
  const navigate = useNavigate()

  return (
    <BackgroundContainer container justifyContent='center'>
      <Container>
        <Title variant='h2'>
          Get Started in 3 Easy Steps
        </Title>
        <Grid container>
          <Column
            num={1}
            mainText='Sign Up'
            subText='Use Interview Slice for free! No cost. No ads. No data selling.'
            navigate={navigate}
          />
          <Column
            num={2}
            mainText='Add Interviews'
            subText='Add your interview details such as the company and position applied for.'
            imgSrc='marketing-images/getting-started-1.png'
            altTxt='List of companies to choose from'
          />
          <Column
            num={3}
            mainText='Update Status'
            subText='Update the status as you progress through interview rounds.'
            imgSrc='marketing-images/getting-started-2.png'
            altTxt='Status options to update job interview'
          />
        </Grid>
      </Container>
    </BackgroundContainer>
  )
}

export default GettingStarted
