import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {
  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    }
  })

  if (!auth.isAuthenticated) {
    return <Navigate to="/" />
  }

  return children
}

export default RequireAuth
