import styled from '@emotion/styled'
import { Button, Grid, TextField, Typography } from '@mui/material'

import theme from '../../theme'

export const ShellContainer = styled.div`
  padding: 24px 32px 24px 32px;
  max-width: 550px;
  border-radius: 25px;
`

export const TitleContainer = styled(Grid)`
  margin-bottom: 12px;
`

export const Title = styled(Typography)`
  font-weight: bold;
`

export const CustomHR = styled.hr`
  height: 2px;
  background-color: ${theme.palette.neutral.main};
  border: none;
  margin: 0;
`

export const SubmitButton = styled(Button)`
  margin: 32px 0 32px 0;
  border-radius: 10px;
  text-transform: none;
  font-weight: bold;
  color: white;
`

export const CustomTextField = styled(TextField)`
  margin-top: 20px;
  margin-bottom:
`

export const CustomTextFieldInputProps = {
  borderRadius: 10,
}

export const BottomTextContainer = styled.div`
  margin: 32px 0 4px 0;
  text-align: center;
`

export const CustomLink = styled(Typography)`
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: ${theme.palette.primary.main};
`

export const GoogleButton = styled(Button)`
  margin-top: 32px;
  border: 1px solid ${theme.palette.neutral.dark};
  border-radius: 10px;
  text-transform: none;
  color: ${theme.palette.neutral.dark};
`

export const DividerWithText = ({ text }) => {
  const StyledTypography = styled(Typography)`
    padding: 0px 24px 0px 24px;
  `

  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <CustomHR />
      </Grid>
      <Grid item>
        <StyledTypography>
          {text}
        </StyledTypography>
      </Grid>
      <Grid item xs>
        <CustomHR />
      </Grid>
    </Grid>
  )
}
