import React from 'react'
import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

const Container = styled(Grid)`
  margin-top: 40px;
  padding: 0 20px 0 20px;
  margin-bottom: 20px;
`

const ColumnHeader = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
`

const TableHeader = () => {
  return (
    <Container container>
      <Grid item xs={3}>
        <ColumnHeader>
          Company
        </ColumnHeader>
      </Grid>
      <Grid item xs={3}>
        <ColumnHeader>
          Position
        </ColumnHeader>
      </Grid>
      <Grid item xs={2}>
        <ColumnHeader>
          Applied
        </ColumnHeader>
      </Grid>
      <Grid item xs={3}>
        <ColumnHeader>
          Status
        </ColumnHeader>
      </Grid>
    </Container>
  )
}

export default TableHeader
