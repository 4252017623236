import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  getAuth,
  sendPasswordResetEmail,
  updateProfile
} from 'firebase/auth'

import { Typography } from '@mui/material'

import PageWrapper from '../components/layout/PageWrapper'
import AccountDetail from '../components/account/AccountDetail'
import theme from '../theme'

const Title = styled(Typography)`
  font-weight: bold;
  margin-top: 20px;
`

const Account = () => {
  const [editingField, setEditingField] = useState(null)
  const [loading, setLoading] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [disablePasswordReset, setDisablePasswordReset] = useState(false)

  const firebaseAuth = getAuth()
  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    }
  })

  const [accountName, setAccountName] = useState(user.displayName)
  const [accountEmail, setAccountEmail] = useState(user.email)

  // Updates firebase display name
  const updateAccountName = async (newName) => {
    await setLoading(true)
    await updateProfile(firebaseAuth.currentUser, {
      displayName: newName,
    }).then(() => {
      // Show success
      setAccountName(newName)
    }).catch((e) => {
      // Show error
      // TODO: Add error alert
      console.log('Error updating display name')
    }).finally(() => {
      setEditingField(null)
      setLoading(false)
    })
  }

  // Resets password and sets success alert
  const handleResetPasswordClick = async () => {
    await setPasswordLoading(true)
    sendPasswordResetEmail(firebaseAuth, user.email)
      .then(() => {
        // Show Success
        setPasswordSuccess(true)
        setDisablePasswordReset(true)
      }).catch((e) => {
        // Show error
        // TODO: Add error alert
        console.log('Error reseting password')
      }).finally(() => {
        setPasswordLoading(false)
      })
  }

  const handleCloseSuccessAlert = () => {
    setPasswordSuccess(false)
  }

  useEffect(() => {
    setAccountName(user.displayName)
    setAccountEmail(user.email)
  }, [user])

  return (
    <>
      <PageWrapper
        backgroundColor={theme.palette.primary.light}
        minHeight='0vh'
        width='800px'
      >
        <Title variant='h1'>
          Account
        </Title>
      </PageWrapper>
      <PageWrapper width='800px'>
        {/* Name field */}
        <AccountDetail
          label='Name'
          userDetail={accountName}
          actionText='Edit'
          onActionClick={() => setEditingField('Name')}
          onUpdate={updateAccountName}
          onCancel={() => setEditingField(null)}
          editing={editingField === 'Name'}
          disabled={editingField && (editingField !== 'Name')}
          loading={loading}
          key='Name'
        />

        {/* Email field */}
        <AccountDetail
          label='Email'
          userDetail={accountEmail}
          key='Email'
        />

        {/* Password field */}
        <AccountDetail
          label='Password'
          userDetail='**********'
          actionText={!disablePasswordReset ? 'Reset Password' : 'Reset Link Sent'}
          onActionClick={handleResetPasswordClick}
          disabled={(editingField && (editingField !== 'Password')) || disablePasswordReset}
          loading={passwordLoading}
          success={passwordSuccess}
          successText='Successfully sent password reset email.'
          onCloseSuccess={handleCloseSuccessAlert}
          key='Password'
        />
      </PageWrapper>
    </>
  )
}

export default Account
