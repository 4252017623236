import { green } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF7272',
      light: '#FFE9E4',
      transparentMain: 'rgba(255, 114, 114, 0.1)',
    },
    secondary: {
      main: '#484D52',
    },
    tertiary: {
      orange: '#FFB067',
      cyan: '#ACEEF3',
    },
    neutral: {
      main: '#D6D6D6',
      dark: '#484D52',
      light: '#F5F5F5',
      transparent: 'rgba(0, 0, 0, 0.02)',
      transparentDark: 'rgba(0, 0, 0, 0.1)',
    },
    text: {
      main: '#484D52',
      light: '#686868',
    },
    success: {
      main: green[600],
    }
    // error: {
    //   main: '#E53935',
    // },
  },
  typography: {
    allVariants: {
      color: '#484D52'
    },
    // fontFamily: '...',
    body1: {
      fontSize: '0.9rem',
    },
    h1: {
      fontSize: '2.5rem',
      color: '#484D52',
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontSize: '2rem',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.5rem',
    }
  }
})

export default theme
