import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

const AccountDetailLabel = styled(Typography)`
  font-weight: bold;
  margin-bottom: 40px;
`

const UserDetailText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light,
  marginBottom: 40,
}))

const EditingFieldContainer = styled.div`
  margin-bottom: 40px;
`

const GreyButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.main,
  textTransform: 'none',
  borderRadius: 10,
  padding: '0 12px',
  '&:hover': {
    backgroundColor: theme.palette.neutral.transparentDark,
  },
}))

const PrimaryButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  textTransform: 'none',
  borderRadius: 10,
  padding: '0 12px',
  '&:hover': {
    backgroundColor: theme.palette.primary.transparentMain,
  },
}))

const AccountDetail = ({
  label,
  userDetail,
  actionText,
  onActionClick,
  onUpdate,
  onCancel,
  editing,
  disabled,
  loading,
  success,
  successText,
  onCloseSuccess,
}) => {
  const [newDetail, setNewDetail] = useState(userDetail)

  useEffect(() => {
    setNewDetail(userDetail)
  }, [userDetail])

  const handleUpdateClick = () => {
    if (newDetail !== userDetail) {
      onUpdate(newDetail)
    } else {
      onCancel()
    }
  }

  const EditableActions = () => {
    if (!onActionClick || !actionText) {
      return null
    }

    if (editing) {
      return (
        <>
          <GreyButton onClick={onCancel}>
            Cancel
          </GreyButton>
          <PrimaryButton onClick={handleUpdateClick}>
            {loading ? (
              <CircularProgress size={16} sx={{ padding: '4px 16px' }} />
            ) : 'Update' }
          </PrimaryButton>
        </>
      ) 
    } else {
      return (
        <GreyButton
          disabled={disabled}
          onClick={onActionClick}
          sx={{
            textDecoration: 'underline',
            ':hover': {
              textDecoration: 'underline',
            }
          }}
        >
          {loading ? (
            <CircularProgress size={16} sx={{ padding: '4px 16px' }} />
          ) : actionText}
        </GreyButton>
      )
    }
  }

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item xs>
          <AccountDetailLabel>
            {label}
          </AccountDetailLabel>
        </Grid>
        <Grid item>
          <EditableActions />
        </Grid>
      </Grid>
      {editing ? (
        <EditingFieldContainer>
          <TextField
            value={newDetail}
            placeholder={label}
            InputProps={{
              style: {
                borderRadius: 10,
              },
            }}
            inputProps={{
              maxLength: 40,
            }}
            fullWidth
            onChange={(e) => {
              setNewDetail(e.target.value)
            }}
          />
        </EditingFieldContainer>
      ) : (
        <UserDetailText>
          {userDetail || 'N/A'}
        </UserDetailText>
      )}
      <Snackbar open={success} autoHideDuration={3000} onClose={onCloseSuccess}>
        <Alert
          severity='success'
          // color='primary'
          variant='filled'
          onClose={onCloseSuccess}
          sx={{
            borderRadius: 10,
          }}
        >
          {successText}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AccountDetail
