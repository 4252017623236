import React from 'react'

import {
  Typography,
  Grid,
  CircularProgress,
  Avatar,
} from '@mui/material'

import {
  BottomTextContainer,
  CustomLink,
  DividerWithText,
  GoogleButton,
  ShellContainer,
  SubmitButton,
  Title,
  TitleContainer
} from './LoginSignupShellComponents'

const LogInSignUpShell = ({
  onSubmit,
  title,
  children,
  bottomTextObj,
  loading,
  googleText,
  onGoogleClick,
}) => {

  return (
    <Grid container justifyContent='center'>
      <ShellContainer>
        <TitleContainer container alignItems='center'>
          <Grid item xs>
            <Title variant='h5'>
              {title}
            </Title>
          </Grid>
        </TitleContainer>
        {/* All text fields should come in as children */}
        {children}
        <SubmitButton
          type='submit'
          color='primary'
          variant='contained'
          onClick={onSubmit}
          fullWidth
          disableElevation
          size='large'
        >
          {!loading ? (
            <>
              {title}
            </>
          ) : (
            <span style={{ height: 24 }}>
              <CircularProgress size={20} color='secondary' />
            </span>
          )}
        </SubmitButton>
        {bottomTextObj && (
          <>
            <DividerWithText text='or' />
            <GoogleButton
              fullWidth
              disableElevation
              size='large'
              onClick={onGoogleClick}
            >
              <Avatar
                sx={{
                  height: 30,
                  width: 30,
                  marginRight: '4px'
                }}
                src='/msc-images/google-64x64.png'
              />
              {googleText}
            </GoogleButton>
            <BottomTextContainer>
              <Typography display='inline'>
                {bottomTextObj.initialText}
                {' '}
              </Typography>
              <CustomLink
                display='inline'
                onClick={bottomTextObj.onClick}
              >
                {bottomTextObj.actionText}
              </CustomLink>
            </BottomTextContainer>
          </>
        )}
      </ShellContainer>
    </Grid>
  )
}

export default LogInSignUpShell
