import React from 'react'
import styled from '@emotion/styled'
import theme from '../../theme'
import { Grid, Typography } from '@mui/material'

const BackgroundContainer = styled(Grid)`
  background: linear-gradient(
    to bottom,
    ${theme.palette.primary.light} 0%,
    white 50%,
    white 50%,
    white 100%
  );
  padding: 20px 0px 100px 0px;
`

const Container = styled.div`
  padding: 0px 40px 0px 40px;
  max-width: 800px;
`

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`

const SubTitle = styled(Typography)`
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: ${theme.palette.text.light}
`

const Streamline = () => {
  return (
    <BackgroundContainer container justifyContent='center'>
      <Container>
        <Grid item xs={12}>
          <Title variant='h2'>
            Streamline Your Job Search with Our Interview Tracker
          </Title>
        </Grid>
        <Grid item xs={12}>
          <SubTitle variant='h6'>
            Effortlessly stay organized - Land your dream job faster.
          </SubTitle>
        </Grid>
        <Grid item xs={12}>
          <img
            src='marketing-images/interviews.png'
            width='100%'
            alt='List of interviews example from Interview Slice Home'
          />
        </Grid>
      </Container>
    </BackgroundContainer>
  )
}

export default Streamline
