import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { Alert, Button, Grid, Skeleton, Snackbar, Typography } from '@mui/material'

import InterviewContainer from '../components/home/InterviewContainer/InterviewContainer'
import TableHeader from '../components/home/TableHeader'
import SortInterviews from '../components/home/SortInterviews'
import TitleRow from '../components/home/TitleRow'

const Title = styled(Typography)`
  font-weight: bold;
`

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light,
}))

const InnerWrapper = styled.div`
  width: 100%;
`

const NoInterviewsContainer = styled(Grid)`
  text-align: center;
`

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  textTransform: 'none',
  padding: '12px 32px 12px 32px',
  fontWeight: 'bold',
  fontSize: '1rem',
  color: 'white',
  marginTop: 20,
}))

const UserHome = () => {
  const navigate = useNavigate()
  const [showInterviewRemoved, setShowInterviewRemoved] = useState(false)

  const { interviews, loading } = useSelector((state) => {
    return {
      interviews: state.interviews.interviews,
      loading: state.interviews.loading,
    }
  })

  const handleCloseAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowInterviewRemoved(false);
  }

  const renderInterviews = () => {
    if (loading) {
      return (
        <>
          {/* TITLE ROW */}
          <TitleRow />
          {/* TABLE HEADER */}
          <TableHeader />
          {/* TABLE CONTENT */}
          <CustomSkeleton />
          <CustomSkeleton />
          <CustomSkeleton />
        </>
      )
    }

    if (Object.entries(interviews).length > 0) {
      return (
        <>
          {/* TITLE ROW */}
          <TitleRow />
          {/* TABLE HEADER */}
          <TableHeader />
          {/* TABLE CONTENT */}
          <InterviewsBlock />
        </>
      )
    }

    return (
      <NoInterviewsBlock />
    )
  }

  const CustomSkeleton = () => {
    return (
      <Skeleton
        variant='rounded'
        height={43 + 20 + 2}
        sx={{
          marginTop: '16px',
          borderRadius: '25px',
        }}
      />
    )
  }

  const NoInterviewsBlock = () => {
    return (
      <NoInterviewsContainer container justifyContent='center'>
        <Grid item xs={12}>
          <Title variant='h2'>
            No Interviews Found
          </Title>
        </Grid>
        <Grid item xs={12}>
          <SubTitle variant='h6'>
            Add your first interview
          </SubTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            variant='contained'
            disableElevation
            onClick={() => navigate('/add-interview')}
            color='primary'
          >
            Add New
          </StyledButton>
        </Grid>
      </NoInterviewsContainer>
    )
  }

  const InterviewsBlock = () => {
    return (
      <SortInterviews>
        {Object.entries(interviews).map((interviewEntry) => {
          const interviewKey = interviewEntry[0]
          const interview = interviewEntry[1]
          return (
            <InterviewContainer
              interviewDetails={interview}
              interviewKey={interviewKey}
              key={interviewKey}
              onInterviewRemoved={() => setShowInterviewRemoved(true)}
            />
          )
        })}
      </SortInterviews>
    )
  }

  return (
    <InnerWrapper>
      {renderInterviews()}
      {/* ALERTS */}
      <Snackbar open={showInterviewRemoved} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert
          severity='info'
          color='primary'
          variant='filled'
          onClose={handleCloseAlert}
          sx={{
            borderRadius: 10,
          }}
        >
          Successfully deleted interview.
        </Alert>
      </Snackbar>
    </InnerWrapper>
  )
}

export default UserHome
