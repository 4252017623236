import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '@mui/material'


const Container = styled.div`
  padding: 40px 20px 0px 20px
`

const Title = styled(Typography)`
  font-weight: bold;
`

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light,
  paddingTop: 30,
}))

const StyledImg = styled.img`
  padding-top: 30px;
  object-fit: contain;
`

const CTAButton = styled(Button)`
  text-transform: none; 
  padding: 12px 32px 12px 32px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 30px;
  margin-top: 20px;
`

const MarketingHomeMobile = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Title variant='h1'>
        Control the chaos in finding your next job
      </Title>
      <SubTitle variant='h2'>
        Track all your interviews in one place.
      </SubTitle>
      <StyledImg
        src='marketing-images/marketing-image.png'
        width='100%'
        alt='list of interviews with company logos'
      />
      <CTAButton
        variant='contained'
        color='primary'
        disableElevation
        fullWidth
        onClick={() => navigate('/signup')}
      >
        Join for free
      </CTAButton>
    </Container>
  )
}

export default MarketingHomeMobile
