import {
  lightBlue,
  red,
  green,
  lightGreen,
} from '@mui/material/colors'

export const ERROR_MESSAGES = {
  // Signup/Login Errors
  EMAIL_OR_PASS_INVALID_TEXT: 'Email or password invalid',
  EMAIL_INVALID_TEXT: 'Please enter a valid email',
  EMAIL_REQUIRED_TEXT: 'Email is required',
  EMAIL_EXISTS_TEXT: 'Email already exists',
  PASSWORD_REQUIRED_TEXT: 'Password is required',
  PASSWORD2_REQUIRED_TEXT: 'Please confirm your password',
  PASSWORD_MUST_MATCH_TEXT: 'Passwords must match',
  ACCOUNT_DISABLED_MESSAGE: 'Access to this account has been temporarily disabled due to many failed login attempts. Please reset your password or contact us.',
  // Password Reset Errors
  ACCOUNT_NOT_FOUND: 'Unable to find account associated with the email entered. Please try a different email.',
  // Generic Errors
  GENERIC_ERROR_FOUND: 'Uh oh! Something went wrong. Please try again later.',
  // Add Interview Form Errors
  COMPANY_EMPTY: 'Please add a company',
  POSITION_EMPTY: 'Please add a position',
  // Contact Us Errors
  NAME_REQUIRED_TEXT: 'Name is required',
  TOPIC_REQUIRED_TEXT: 'Please tell us what your message is about.',
  MESSAGE_REQUIRED_TEXT: `Let us know what's on your mind!`,
}

// Screen sizes
export const MOBILE_MAX_WIDTH = '600px'

// Interview Status Options
export const statusOptions = [{
    label: 'Applied',
    color: lightBlue[300],
    value: 0,
  }, {
    label: 'Round 1 Interview',
    color: lightGreen[300],
    value: 1,
  }, {
    label: 'Round 2 Interview',
    color: lightGreen[500],
    value: 2,
  }, {
    label: 'Round 3 Interview',
    color: green[500],
    value: 3,
  }, {
    label: 'Offer Received',
    color: green[800],
    value: 4,
  }, {
    label: 'Rejected',
    color: red[400],
    value: -1,
  },
  // {
  //   label: 'Wishlist',
  //   color: yellow[800],
  // },
]

export const companiesList = [{
    label: 'Google',
    logo: 'google',
  }, {
    label: 'Amazon',
    logo: 'amazon',
  }, {
    label: 'Meta',
    logo: 'meta',
  }, {
    label: 'Apple',
    logo: 'apple',
  }, {
    label: 'Netflix',
    logo: 'netflix',
  }, {
    label: 'AirBnb',
    logo: 'airbnb',
  }, {
    label: 'Uber',
    logo: 'uber',
  }, {
    label: 'Microsoft',
    logo: 'microsoft',
  }, {
    label: 'Adobe',
    logo: 'adobe',
  }, {
    label: 'Snowflake',
    logo: 'snowflake',
  }, {
    label: 'Dropbox',
    logo: 'dropbox',
  }, {
    label: 'Twilio',
    logo: 'twilio',
  }, {
    label: 'Databricks',
    logo: 'databricks',
  }, {
    label: 'Salesforce',
    logo: 'salesforce',
  }, {
    label: 'Asana',
    logo: 'asana',
  }, {
    label: 'Affirm',
    logo: 'affirm',
  }, {
    label: 'AMD',
    logo: 'amd',
  }, {
    label: 'Atlassian',
    logo: 'atlassian',
  }, {
    label: 'Bloomberg',
    logo: 'bloomberg',
  }, {
    label: 'Box',
    logo: 'box',
  }, {
    label: 'Bytedance',
    logo: 'bytedance',
  }, {
    label: 'Capital One',
    logo: 'capitalone',
  }, {
    label: 'Chewy',
    logo: 'chewy',
  }, {
    label: 'Cisco',
    logo: 'cisco',
  }, {
    label: 'Coinbase',
    logo: 'coinbase',
  }, {
    label: 'Cruise',
    logo: 'cruise',
  }, {
    label: 'Discord',
    logo: 'discord',
  }, {
    label: 'DoorDash',
    logo: 'doordash',
  }, {
    label: 'Etsy',
    logo: 'etsy',
  }, {
    label: 'GitHub',
    logo: 'github',
  }, {
    label: 'Indeed',
    logo: 'indeed',
  }, {
    label: 'Instacart',
    logo: 'instacart',
  }, {
    label: 'Intel',
    logo: 'intel',
  }, {
    label: 'Intuit',
    logo: 'intuit',
  }, {
    label: 'Lyft',
    logo: 'lyft',
  }, {
    label: 'MongoDB',
    logo: 'mongodb',
  }, {
    label: 'Nextdoor',
    logo: 'nextdoor',
  }, {
    label: 'Nvidia',
    logo: 'nvidia',
  }, {
    label: 'Okta',
    logo: 'okta',
  }, {
    label: 'Opendoor',
    logo: 'opendoor',
  }, {
    label: 'Oracle',
    logo: 'oracle',
  }, {
    label: 'Palantir',
    logo: 'palantir',
  }, {
    label: 'Patreon',
    logo: 'patreon',
  }, {
    label: 'PayPal',
    logo: 'paypal',
  }, {
    label: 'Pinterest',
    logo: 'pinterest',
  }, {
    label: 'Plaid',
    logo: 'plaid',
  }, {
    label: 'Reddit',
    logo: 'reddit',
  }, {
    label: 'Robinhood',
    logo: 'robinhood',
  }, {
    label: 'Roblox',
    logo: 'roblox',
  }, {
    label: 'Shopify',
    logo: 'shopify',
  }, {
    label: 'Splunk',
    logo: 'splunk',
  }, {
    label: 'Spotify',
    logo: 'spotify',
  }, {
    label: 'Square',
    logo: 'square',
  }, {
    label: 'Stripe',
    logo: 'stripe',
  }, {
    label: 'Target',
    logo: 'target',
  }, {
    label: 'Tesla',
    logo: 'tesla',
  }, {
    label: 'Tinder',
    logo: 'tinder',
  }, {
    label: 'Twitch',
    logo: 'twitch',
  }, {
    label: 'Twitter',
    logo: 'twitter',
  }, {
    label: 'Two Sigma',
    logo: 'twosigma',
  }, {
    label: 'Wayfair',
    logo: 'wayfair',
  }, {
    label: 'WeWork',
    logo: 'wework',
  }, {
    label: 'Workday',
    logo: 'workday',
  }, {
    label: 'Yelp',
    logo: 'yelp',
  }, {
    label: 'Zillow',
    logo: 'zillow',
  }, {
    label: 'Zoom',
    logo: 'zoom',
  }, {
    label: 'LinkedIn',
    logo: 'linkedin',
  }
]
