import React from 'react'
import { Grid } from '@mui/material'

const PageWrapper = ({
  children,
  width,
  padding,
  backgroundColor,
  minHeight,
}) => {
  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        padding: padding || '40px',
        minHeight: minHeight || '60vh',
        backgroundColor: backgroundColor,
      }}
    >
      <div style={{ width: width || '1000px' }}>
        {children}
      </div>
    </Grid>
  )
}

export default PageWrapper
