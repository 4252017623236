import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

const aboutLinks = [
  // {
  //   label: 'About Us',
  //   href: '#',
  // },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
  // {
  //   label: 'Terms of Service',
  //   href: '#',
  // },
]

const supportLinks = [
  // {
  //   label: 'FAQ',
  //   href: '#',
  // },
  {
    label: 'Contact Us',
    href: '/contact',
  },
]

const Container = styled(Grid)(({ theme }) => ({
  padding: '80px 0 80px 0',
  marginTop: 100,
  backgroundColor: theme.palette.primary.light,
}))

const StyledImg = styled.img`
  margin-top: -120px;
  float: right;
`

const LinkColumnContainer = styled(Grid)`
  padding-right: 40px;
`

const LinKColumnHeader = styled(Typography)`
  font-weight: bold;
`

const LinkContainer = styled.div`
  // max-width: 460px this is half
  margin-top: 16px;
  width: 200px;
`

const Link = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const LinkColumn = ({ header, links }) => {
  const navigate = useNavigate()

  const onLinkClick = (href) => {
    navigate(href)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <LinkColumnContainer item xs={6}>
      <LinKColumnHeader>
        {header}
      </LinKColumnHeader>
      {links && links.map((linkObj, i) => {
        const { label, href } = linkObj

        if (!label || !href) {
          return null
        }

        return (
          <LinkContainer key={i}>
            <Link
              onClick={() => onLinkClick(href)}
              display='inline'
            >
              {label}
            </Link>
          </LinkContainer>
        )
      })}
    </LinkColumnContainer>
  )
}

const Footer = () => {
  return (
    <Container container>
      <Grid item xs={1} />
      <Grid item xs={7}>
        <Grid container>
          <LinkColumn header='About' links={aboutLinks} />
          <LinkColumn header='Support' links={supportLinks} />
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <StyledImg src='footer-image.png' height='150px' alt='a few interview slices' />
      </Grid>
      <Grid item xs={1} />
    </Container>
  )
}

export default Footer
