import React from 'react'

import styled from '@emotion/styled'
import theme from '../../theme'

const StyledList = styled.ul`
  margin-block-end: 0px;
  margin-block-start: 20px;
  padding-inline-start: 14px;
  margin-left: 14px;
  color: #d32f2f;
`

const StyledListItem = styled.li`
  margin-bottom: 4px;
  color: ${props => props.isIncluded && theme.typography.allVariants.color};
  font-size: 0.75rem;
  line-height: 1.66;
`

const PasswordErrorBlock = ({ password }) => {

  const includesUppercaseRegex = new RegExp('(?=.*[A-Z])')
  const includesNumberRegex = new RegExp('(?=.*[0-9])')

  const passwordLengthGood = password.length >= 8
  const passwordContainsNumber = includesNumberRegex.test(password)
  const passwordIncludesUppercase = includesUppercaseRegex.test(password)

  if (passwordLengthGood && passwordContainsNumber && passwordIncludesUppercase) {
    // Maybe show 'all checks pass' sort of message
    return null
  }

  return (
    <StyledList>
      <StyledListItem isIncluded={passwordLengthGood}>
        Password must be at least 8 characters long
      </StyledListItem>
      <StyledListItem isIncluded={passwordContainsNumber}>
        Must be a combination of letters and numbers
      </StyledListItem>
      <StyledListItem isIncluded={passwordIncludesUppercase}>
        Must contain at least one uppercase letter
      </StyledListItem>
    </StyledList>
  )
}

export default PasswordErrorBlock
