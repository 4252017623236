import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { useDispatch } from 'react-redux'

import { auth, db } from './firebase-config'

import Home from './Pages/Home'
import LogIn from './Pages/LogIn'
import SignUp from './Pages/SignUp'
import PasswordReset from './Pages/PasswordReset'
import PrivacyPolicy from './Pages/PrivacyPolicy.jsx'
import ContactUs from './Pages/ContactUs.jsx'
import Account from './Pages/Account'

import AddInterview from './components/add-interview-form/AddInterview'
import TopNav from './components/layout/TopNav'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { updateAuth } from './redux/auth'
import { updateInterviews, setLoading } from './redux/interviews'
import Footer from './components/layout/Footer'
import MarketingHome from './Pages/MarketingHome'
import RequireAuth from './components/shared/RequireAuth'
import RequireNoAuth from './components/shared/RequireNoAuth'

const App = () => {
  const dispatch = useDispatch()

  const loadUserData = async (uid) => {
    dispatch(setLoading(true))
    const docRef = doc(db, 'interviews', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      dispatch(updateInterviews(docSnap.data()))
    } else {
      console.log('NO DOCUMENT')
    }
    dispatch(setLoading(false))
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is logged in
      const userAttributes = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        uid: user.uid,
      }

      dispatch(updateAuth({
        isAuthenticated: true,
        user: userAttributes,
        loading: false,
      }))

      loadUserData(user.uid)
    } else {
      // User is signed out
      dispatch(updateAuth({
        isAuthenticated: false,
        user: {},
        loading: false,
      }))
    }
  })

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TopNav />
        <Routes>
          {/* Public */}
          <Route path='/' element={
            <RequireNoAuth>
              <MarketingHome />
            </RequireNoAuth>
          } />
          <Route path='/login' element={<LogIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/contact' element={<ContactUs />} />
          {/* Private */}
          <Route path='/home' element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
          <Route path='/password-reset' element={
            <RequireAuth>
              <PasswordReset />
            </RequireAuth>}
          />
          <Route path='/add-interview' element={
            <RequireAuth>
              <AddInterview />
            </RequireAuth>
          } />
          <Route path='/account' element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          } />
          {/* Redirect invalid paths to home screen */}
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
        <Footer />
      </LocalizationProvider>
    </Router>
  )
}

export default App
