import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button, Grid, Typography } from '@mui/material'

import theme from '../../theme'

const Container = styled.div`
  padding: 100px 40px 0px 40px;
  max-width: 800px;
`

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`

const SubTitle = styled(Typography)`
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: ${theme.palette.text.light}
`

const CTAButton = styled(Button)`
  text-transform: none;
  padding: 12px 32px 12px 32px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 30px;
`

const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`

const FinalCTA = () => {
  const navigate = useNavigate()

  return (
    <Grid container justifyContent='center'>
      <Container>
        <Grid item xs={12}>
          <Title variant='h2'>
            Ready to crush the next round of interviews?
          </Title>
        </Grid>
        <Grid item xs={12}>
          <SubTitle variant='h6'>
            Sign up for Interview Slice and let us do the work!
          </SubTitle>
        </Grid>
        <ButtonContainer item xs={12}>
          <CTAButton
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => navigate('/signup')}
          >
            Sign Up Now
          </CTAButton>
        </ButtonContainer>
      </Container>
    </Grid>
  )
}

export default FinalCTA