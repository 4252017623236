import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styled from '@emotion/styled'
import { Button, Grid, Typography, useMediaQuery } from '@mui/material'

import theme from '../../theme'
import UserProfile from './UserProfile'
import { MOBILE_MAX_WIDTH } from '../../constants'

const Container = styled(Grid)`
  padding: 20px;
  // background-color: ${theme.palette.primary.light};
`

const Title = styled(Typography)`
  color: ${theme.palette.text.main};
  font-weight: bold;
  margin: 0;
  cursor: pointer;
`

const LogInButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 20,
  padding: '12px 32px 12px 32px',
  fontWeight: 'bold',
  color: theme.palette.neutral.dark,
  backgroundColor: theme.palette.neutral.main,
  '&:hover': {
    backgroundColor: theme.palette.neutral.main,
    boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  },
}))

const SignUpButton = styled(Button)`
  text-transform: none;
  border-radius: 20px;
  margin-left: 16px;
  color: white;
  font-weight: bold;
  padding: 12px 32px 12px 32px;
`

const LogoImg = styled.img`
  padding-right: 12px;
`

const TopNav = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH})`)

  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    }
  })

  return (
    <Container
      container
      justifyContent='center'
      sx={{
        backgroundColor: auth.isAuthenticated ? theme.palette.primary.light : 'white',
      }}
    >
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{
          width: '1000px',
        }}
      >
        <Grid item>
          <Grid container alignItems='center'>
            <LogoImg src='logo-1.png' height='40' alt='interview slice logo' /> 
            <Title
              variant='h3'
              onClick={() => navigate('/')}
              display='inline'
            >
              Interview Slice
            </Title>
          </Grid>
        </Grid>
        {auth.isAuthenticated ? (
          <Grid item>
            <UserProfile />
          </Grid>
        ) : (
          <Grid item>
            <LogInButton
              variant='contained'
              // color='primary'
              onClick={() => navigate('/login')}
              disableElevation
            >
              Log In
            </LogInButton>
            {!isMobile && (
              <SignUpButton
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </SignUpButton>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default TopNav
