import React from 'react'

import { Avatar } from '@mui/material'

const CompanyAvatar = ({ value, styles }) => {

  const defaultAvatar = (companyObject) => {
    if (companyObject && companyObject.label) {
      return (
        companyObject.label.charAt(0)
      )
    }
    return '?'
  }

  if (value) {
    return (
      <Avatar
        sx={styles}
        src={`/company-logos/${value.logo}-64x64.png`}
      >
        {defaultAvatar(value)}
      </Avatar>
    )
  }

  return null
}

export default CompanyAvatar
