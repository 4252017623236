import styled from '@emotion/styled'

import { Typography } from '@mui/material'

const FieldContainer = styled.div`
  margin-bottom: 20px;
`

const FieldLabel = styled(Typography)`
  font-weight: bold;
`

export const FieldWrapper = ({ children, label }) => {
  return (
    <FieldContainer>
      <FieldLabel>
        {label}
      </FieldLabel>
      {children}
    </FieldContainer>
  )
}

export const CustomTextFieldInputProps = {
  borderRadius: 10,
}
