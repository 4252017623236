import React, { useState } from 'react'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'

import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { auth } from '../firebase-config'
import PageWrapper from '../components/layout/PageWrapper'
import LogInSignUpShell from '../components/auth/LogInSignUpShell'
import { CustomTextField, CustomTextFieldInputProps } from '../components/auth/LoginSignupShellComponents'
import PasswordErrorBlock from '../components/auth/PasswordErrorBlock'
import { ERROR_MESSAGES } from '../constants'

const emptyFieldsState = {
  email: '',
  password: '',
  password2: '',
}

const SignUp = () => {
  const navigate = useNavigate()
  const googleAuthProvider = new GoogleAuthProvider()

  const [fields, setFields] = useState(emptyFieldsState)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const bottomTextObj = {
    initialText: 'Already have an account?',
    onClick: () => navigate('/login'),
    actionText: 'Log In',
  }

  const onChange = (e) => {
    setFields({
      ...fields,
      [e.target.id]: e.target.value,
    })
    // If password is changed, validate and set errors accordingly
    if ((e.target.id === 'password')) {
      setErrors({ ...errors, password: !isPasswordStrong() })
    }
  }

  const onSubmit = () => {
    setLoading(true)
  
    if (validateInput()) {
      // Call firebase function to sign up with email
      createUserWithEmailAndPassword(auth, fields.email, fields.password)
        .then((userCredential) => {
          // Signed in Successfully
          // const user = userCredential.user
          navigate('/home')
        })
        .catch((error) => {
          // const errorCode = error.code
          // const errorMessage = error.message
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  // Validate input and if invalid, set errors
  const validateInput = () => {
    const tempErrors = {}
    // Email checks
    if (validator.isEmpty(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_REQUIRED_TEXT
    } else if (!validator.isEmail(fields.email)) {
      tempErrors.email = ERROR_MESSAGES.EMAIL_INVALID_TEXT
    }
    // Password checks
    if (validator.isEmpty(fields.password)) {
      tempErrors.password = ERROR_MESSAGES.PASSWORD_REQUIRED_TEXT
    } else if (!validator.isStrongPassword(fields.password, { minSymbols: 0 })) {
      tempErrors.password = true
    }
    // Password2 checks
    if (validator.isEmpty(fields.password2)) {
      tempErrors.password2 = ERROR_MESSAGES.PASSWORD2_REQUIRED_TEXT
    } else if (!validator.equals(fields.password, fields.password2)) {
      tempErrors.password2 = ERROR_MESSAGES.PASSWORD_MUST_MATCH_TEXT
    }

    setErrors(tempErrors)

    return (Object.keys(tempErrors).length === 0)
  }

  const isPasswordStrong = () => {
    if (!validator.isStrongPassword(fields.password, { minSymbols: 0 })) {
      // Weak Password
      return false
    }
    // Strong Password
    return true
  }

  const handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      onSubmit()
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleGoogleClick = () => {
    // USE SIGN IN WITH REDIRECT FOR MOBILE
    signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        // Success
        navigate('/home')
      })
      .catch((err) => {
        // Error
        console.log('Google Auth Error on sign up')
      })
  }

  const signupFieldsUI = (
    <>
      {/* Email Field */}
      <CustomTextField
        variant='outlined'
        onChange={onChange}
        value={fields.email}
        error={errors.email}
        helperText={errors.email}
        id='email'
        type='email'
        label='Email'
        placeholder='Email'
        fullWidth
        InputProps={{
          style: CustomTextFieldInputProps,
        }}
      />
      {/* Password Field */}
      <CustomTextField
        variant='outlined'
        onChange={onChange}
        value={fields.password}
        error={errors.password}
        id='password'
        type={showPassword ? 'text' : 'password'}
        label='Password'
        placeholder='Password'
        fullWidth
        InputProps={{
          style: CustomTextFieldInputProps,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {/* Password Error Message */}
      {errors.password && <PasswordErrorBlock password={fields.password} />}
      {/* Confirm Password Field */}
      <CustomTextField
        variant='outlined'
        onChange={onChange}
        onKeyDown={handleKeyPress}
        value={fields.password2}
        error={errors.password2}
        helperText={errors.password2}
        id='password2'
        type='password'
        label='Confirm Password'
        placeholder='Confirm Password'
        fullWidth
        InputProps={{
          style: CustomTextFieldInputProps,
        }}
      />
    </>
  )

  return (
    <PageWrapper padding='0px'>
      <LogInSignUpShell
        title='Sign Up'
        onSubmit={onSubmit}
        bottomTextObj={bottomTextObj}
        loading={loading}
        googleText='Sign up with Google'
        onGoogleClick={handleGoogleClick}
      >
        {signupFieldsUI}
      </LogInSignUpShell>
    </PageWrapper>
  )
}

export default SignUp
