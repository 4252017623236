import { createSlice } from '@reduxjs/toolkit'

const authInitialState = {
  isAuthenticated: false,
  user: {},
  loading: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    updateAuth(state, action) {
      const auth = action.payload
      state = auth
      return state
    }
  }
})

export const { updateAuth } = authSlice.actions

export default authSlice.reducer
