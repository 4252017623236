import React, { useEffect, useState } from 'react'

import {
  Avatar,
  IconButton,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  useAutocomplete
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { companiesList } from '../../constants'
import CompanyAvatar from '../shared/CompanyAvatar'
import theme from '../../theme'

const CompanyAutocomplete = ({ updateSelectedCompany, errorText }) => {

  const [showAddNewButton, setShowAddNewButton] = useState(false)
  const [iconValue, setIconValue] = useState(null)

  const sortedCompaniesList = companiesList.sort((a, b) => {
    const companyA = a.label.toUpperCase()
    const companyB = b.label.toUpperCase()
    return (companyA < companyB) ? -1 : 1;
  })

  const {
    value,
    // getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
   } = useAutocomplete({
    id: 'company-selector-autocomplete',
    options: sortedCompaniesList,
    freeSolo: true,
  })

  // const showAddNewButton = Boolean(inputValue)

  useEffect(() => {
    setIconValue(value)

    if (value) {
      updateSelectedCompany(value)
    } else {
      updateSelectedCompany({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleAddCustomCompany = () => {
      const company = {
        label: inputValue,
      }
      updateSelectedCompany(company)
      setIconValue(company)
      setShowAddNewButton(false)
  }

  const DropDownButton = () => {
    return (
      <InputAdornment position='end'>
        <IconButton>
          {(groupedOptions.length > 0) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </InputAdornment>
    )
  }

  // TODO: On CLICK close the list
  const AddNewButton = () => {
    return (
      <ListItemButton onClick={handleAddCustomCompany}>
        <ListItemAvatar>
          <Avatar
            sx={{
              height: 30,
              width: 30,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          {`Add '${inputValue}'`}
        </ListItemText>
      </ListItemButton>
    )
  }

  return (
    <>
      <TextField
        {...getInputProps()}
        placeholder='Eg. Google'
        InputProps={{
          style: {
            borderRadius: 10
          },
          endAdornment: <DropDownButton />,
          startAdornment: (
            <CompanyAvatar
              value={iconValue}
              styles={{
                height: 30,
                width: 30,
                marginRight: '8px',
              }}
            />
          ),

        }}
        error={!!errorText}
        helperText={errorText}
        fullWidth
        onFocus={() => {
          getInputProps().onFocus()
          setShowAddNewButton(true)
        }}
        onBlur={() => {
          getInputProps().onBlur()
          setShowAddNewButton(false)
        }}
        onMouseDown={() => {
          getInputProps().onMouseDown()
          if (!inputValue) {
            setShowAddNewButton(!showAddNewButton)
          } else {
            setShowAddNewButton(true)
          }
        }}
      />
      {(groupedOptions.length > 0 || showAddNewButton) ? (
        <List
          {...getListboxProps()}
          sx={{
            border: '2px solid black',
            maxHeight: 200,
            overflow: 'auto',
            borderRadius: '10px',
            position: 'absolute',
            zIndex: 1,
            backgroundColor: 'white',
            width: 300,
            marginTop: '5px',
          }}
        >
          {groupedOptions.map((option, index) => (
            <ListItemButton
              {...getOptionProps({ option, index })}
              onClick={(e) => {
                getOptionProps({ option, index }).onClick(e)
                setShowAddNewButton(false)
              }}
            >
              <ListItemAvatar>
                <CompanyAvatar
                  value={option}
                  styles={{
                    height: 30,
                    width: 30,
                  }}
                />
              </ListItemAvatar>
              <ListItemText>
                {option.label}
              </ListItemText>
            </ListItemButton>
          ))}
          {/* TODO: WHEN WE SELECT, WE NEED TO CLOSE THE LIST */}
          {showAddNewButton && (
            <AddNewButton />
          )}
        </List>
      ) : null}
    </>
  )
}

export default CompanyAutocomplete
