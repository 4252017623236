import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { MOBILE_MAX_WIDTH } from '../../constants'

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  textTransform: 'none',
  padding: '12px 32px 12px 32px',
  fontWeight: 'bold',
  fontSize: '1rem',
  // backgroundColor: theme.palette.neutral.main,
  // color: theme.palette.neutral.light,
  // '&:hover': {
  //   backgroundColor: theme.palette.neutral.main,
  //   boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  // },
}))

const Title = styled(Typography)`
  font-weight: bold;
  padding: 10px 0px;
`

const TitleRow = () => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH})`)
  const navigate = useNavigate()

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid item>
        <Title variant='h1'>
          My Interviews
        </Title>
      </Grid>
      <Grid item>
        {isMobile ? (
          <IconButton onClick={() => navigate('/add-interview')}>
            <AddCircleIcon
              sx={{ fontSize: '2rem' }}
              color='secondary'
            />
          </IconButton>
        ) : (
          <StyledButton
            variant='contained'
            disableElevation
            onClick={() => navigate('/add-interview')}
            color='secondary'
          >
            Add New
          </StyledButton>
        )}
      </Grid>
    </Grid>
  )
}

export default TitleRow
