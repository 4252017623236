import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material'

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    padding: '10px',
  }
}))

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  textTransform: 'none',
  backgroundColor: theme.palette.error.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  },
}))

const CancelButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 20,
  fontWeight: 'bold',
  color: theme.palette.neutral.dark,
  backgroundColor: theme.palette.neutral.main,
  '&:hover': {
    backgroundColor: theme.palette.neutral.main,
    boxShadow: 'inset 0 0 0 50px rgba(0, 0, 0, 0.2)',
  },
}))

const CustomDialog = ({
  open,
  handleClose,
  title,
  contentText,
  actionButtonText,
  actionClick,
  buttonType,
}) => {

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: 'bold' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton
          variant='contained'
          color='primary'
          onClick={handleClose}
          disableElevation
        >
          Cancel
        </CancelButton>
        <ActionButton
          variant='contained'
          color='primary'
          onClick={actionClick}
          disableElevation
        >
          {actionButtonText}
        </ActionButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default CustomDialog
