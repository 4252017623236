import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/relativeTime'
import styled from '@emotion/styled'
import { doc, updateDoc } from 'firebase/firestore'

import {
  Alert,
  CircularProgress,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Snackbar,
  Typography
} from '@mui/material'
import { Circle } from '@mui/icons-material'

import theme from '../../../theme'
import CompanyAvatar from '../../shared/CompanyAvatar'
import { statusOptions } from '../../../constants'
import { db } from '../../../firebase-config'

dayjs.extend(updateLocale)

const Container = styled(Grid)`
  padding: 26px 10px 26px 0px;
  border-bottom: 1px solid ${theme.palette.neutral.main};
`

const CompanyRoleContainer = styled(Grid)`
  padding-left: 10px;
`

const CompanyLabel = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
`

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-root': {
    border: 'solid 1px black',
  },
  '& .MuiInputBase-input': {
    borderRadius: '25px',
    position: 'relative',
    fontSize: '14px',
    color: theme.typography.allVariants.color,
    padding: '10px 26px 10px 12px',
    width: '100%',
    '&:focus': {
      borderRadius: '25px',
    },
  },
}))

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px,
  '& .MuiSvgIcon-root': {
    marginRight: 20px,
  },
`

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 10,
      marginTop: 5,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
};

const InterviewContainerMobile = ({ interviewDetails, interviewKey, onInterviewRemoved }) => {
  const {
    company,
    position,
    // dateApplied: dateAppliedString,
    status
  } = interviewDetails

  // We need to stringify and parse our status object here to avoid MUI errors
  const [selectedStatus, setSelectedStatus] = useState(JSON.stringify({
    label: status.label,
    color: status.color,
    value: status.value,
  }))
  const [statusUpdating, setStatusUpdating] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    }
  })

  const handleUpdateStatus = async (e) => {
    await setStatusUpdating(true)
    const parsedValue = JSON.parse(e.target.value)
    const interviewRef = doc(db, 'interviews', user.uid)

    await updateDoc(interviewRef, {
      [`${interviewKey}.status`]: parsedValue,
    }).then(() => {
      setShowSuccess(true)
    }).catch((e) => {
      setShowError(true)
    }).finally(() => {
      setStatusUpdating(false)
    })

    setSelectedStatus(e.target.value)
  }

  const handleCloseSuccess = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSuccess(false);
  }

  const handleCloseError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowError(false);
  }

  const CompanyLogoColumn = () => {
    return (
      <Grid container alignItems='center'>
        <Grid item>
          <CompanyAvatar
            value={company}
            styles={{
              height: 40,
              width: 40,
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const CompanyRoleColumn = () => {
    return (
      <CompanyRoleContainer container direction='column'>
        <Grid item>
          <CompanyLabel>
            {company.label}
          </CompanyLabel>
        </Grid>
        <Grid item>
          <Typography>
            {position}
          </Typography>
        </Grid>
      </CompanyRoleContainer>
    )
  }

  const StatusColumn = () => {
    return (
      <Select
        value={selectedStatus || ''}
        defaultValue={''}
        onChange={handleUpdateStatus}
        renderValue={(value) => {
          const parsedValue = JSON.parse(value)
          if (statusUpdating) {
            return (
              <Grid container alignItems='center' justifyContent='center'>
                <CircularProgress size={18} />
              </Grid>
            )
          }
          return parsedValue.label
        }}
        autoWidth
        sx={{
          width: '100%',
          border: `solid 1px ${theme.palette.neutral.transparentDark}`,
          backgroundColor: 'white',
          borderRadius: 10,
        }}
        input={<BootstrapInput />}
        startAdornment={
          !statusUpdating ? (
            <Circle
              sx={{
                color: JSON.parse(selectedStatus).color,
                paddingLeft: '8px',
              }}
            />
          ) : null
        }
        MenuProps={MenuProps}
      >
        {statusOptions.map((currStatus, i) => {
          return (
            <StyledMenuItem
              value={JSON.stringify({
                label: currStatus.label,
                color: currStatus.color,
                value: currStatus.value,
              })}
              sx={{ fontSize: '14px' }}
              key={i}
            >
              <Circle sx={{ color: currStatus.color, marginRight: '12px' }} />
              {currStatus.label}
            </StyledMenuItem>
          )
        })}
      </Select>
    )
  }

  return (
    <>
      <Container container alignItems='center'>
        <Grid item xs={2}>
          <CompanyLogoColumn />
        </Grid>
        <Grid item xs={4}>
          <CompanyRoleColumn />
        </Grid>
        <Grid item xs={6}>
          <StatusColumn />
        </Grid>
      </Container>
      {/* Success alert */}
      <Snackbar open={showSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert
          severity='success'
          onClose={handleCloseSuccess}
          variant='filled'
          sx={{
            borderRadius: 10,
          }}
        >
          Successfully updated status.
        </Alert>
      </Snackbar>
      {/* Error alert */}
      <Snackbar open={showError} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert
          severity='error'
          onClose={handleCloseError}
          variant='filled'
          sx={{
            borderRadius: 10,
          }}
        >
          Error updating status.
        </Alert>
      </Snackbar>
    </>
  )
}

export default InterviewContainerMobile
