import React from 'react'

import PageWrapper from '../components/layout/PageWrapper'
import { PrivacyPolicyHTML } from './PrivacyPolicyHTML'

const privacyPolicyObj = { __html: PrivacyPolicyHTML }

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <div dangerouslySetInnerHTML={privacyPolicyObj} />
    </PageWrapper>
  )
}

export default PrivacyPolicy
