import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { MOBILE_MAX_WIDTH } from '../constants'
import MarketingHomeMobile from './MarketingHomeMobile'
import GettingStarted from '../components/marketing/GettingStarted'
import Streamline from '../components/marketing/Streamline'
import theme from '../theme'
import FinalCTA from '../components/marketing/FinalCTA'

const Container = styled(Grid)`
  margin-top: 100px;
`

const LeftTextContainer = styled.div`
  max-width: 460px;
  padding-right: 40px;
  padding-left: 20px;
  float: right;
`

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 3rem;
`

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light,
  paddingTop: 30,
}))

const StyledImg = styled.img`
  padding-left: 40px;
  object-fit: contain;
`

const CTAButton = styled(Button)`
  text-transform: none; 
  padding: 12px 32px 12px 32px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 30px;
  margin-top: 40px;
`

export const CustomHR = styled.hr`
  height: 2px;
  background-color: ${theme.palette.neutral.main};
  border: none;
  margin: 0;
`

const MarketingHome = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH})`)

  if (isMobile) {
    return (
      <MarketingHomeMobile />
    )
  }

  return (
    <Container container>
      <Grid item xs={6}>
        <LeftTextContainer>
          <Title variant='h1'>
            Control the chaos in finding your next job
          </Title>
          <SubTitle variant='h2'>
            Track all your interviews in one place.
          </SubTitle>
          <CTAButton
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => navigate('/signup')}
          >
            Join for free
          </CTAButton>
        </LeftTextContainer>
      </Grid>
      <Grid item xs={6}>
        <StyledImg src='marketing-images/marketing-image.png' width='100%' alt='list of interviews with company logos' />
      </Grid>
      <Grid item xs={12}>
        <GettingStarted />
      </Grid>
      <Grid item xs={12}>
        <Streamline />
      </Grid>
      <Grid item xs={12}>
        <CustomHR />
      </Grid>
      <Grid item xs={12}>
        <FinalCTA />
      </Grid>
    </Container>
  )
}

export default MarketingHome
