import React from 'react'
import { useSelector } from 'react-redux'

import UserHome from './UserHome'
import UserHomeMobile from './UserHomeMobile'
import MarketingHome from './MarketingHome'
import PageWrapper from '../components/layout/PageWrapper'
import { MOBILE_MAX_WIDTH } from '../constants'

import { useMediaQuery } from '@mui/material'

const Home = () => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH})`)

  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    }
  })

  if (auth.loading) {
    return null
  }

  // Logged In
  if (auth && auth.isAuthenticated) {
    if (isMobile) {
      return (
        <PageWrapper padding='26px'>
          <UserHomeMobile />
        </PageWrapper>
      )
    }

    return (
      <PageWrapper width='800px'>
        <UserHome />
      </PageWrapper>
    )
  }

  // Logged Out
  return (
    <MarketingHome />
  )
}

export default Home
