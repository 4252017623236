import React from 'react'
import dayjs from 'dayjs'

// Sorts by company
// const sortByCompany = (a, b) => {
//   const companyA = a.props.interviewDetails.company.label.toUpperCase()
//   const companyB = b.props.interviewDetails.company.label.toUpperCase()
//   return (companyA < companyB) ? -1 : 1
// }

// Sorts the most recent interviews on top
const sortByDateApplied = (a, b) => {
  const dateAppliedA = a.props.interviewDetails['dateApplied']
  const dateAppliedB = b.props.interviewDetails['dateApplied']
  return dayjs(dateAppliedA).isBefore(dayjs(dateAppliedB)) ? 1 : -1
}

// Sorts the most advanced interviews to the top
const sortByStatus = (a, b) => {
  const statusA = a.props.interviewDetails['status'].value
  const statusB = b.props.interviewDetails['status'].value
  return (statusA < statusB) ? 1 : -1
}

// Sorts the most advanced interviews first, then by date
const sortByDateAndStatus = (a, b) => {
  const interviewA = a.props.interviewDetails
  const interviewB = b.props.interviewDetails
  if (interviewA['status'].value === interviewB['status'].value) {
    // Sort by date when statuses are the same
    const dateA = interviewA['dateApplied']
    const dateB = interviewB['dateApplied']
    return dayjs(dateA).isBefore(dayjs(dateB)) ? 1 : -1
  }
  return (interviewA['status'].value < interviewB['status'].value) ? 1 : -1
}

const SortInterviews = ({ children, sortBy }) => {
  const defaultSort = React.Children.toArray(children).sort(sortByDateAndStatus)

  if (sortBy) {
    switch(sortBy) {
      case 'dateApplied':
        return React.Children.toArray(children).sort(sortByDateApplied)
      case 'status':
        return React.Children.toArray(children).sort(sortByStatus)
      case 'default':
      default:
        return defaultSort
    }
  }
  
  return defaultSort
}

export default SortInterviews
